@import "src/styles/LNQglobalVars";

.linq_logo {
  display: block;
  text-align: left;
}

.auth {
  &__container {
    background-color: $grey_f6;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container_flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: $borderRadius_8;
  }

  &__title {
    font-size: 24px;
    line-height: normal;
    font-weight: bold;
    color: $black;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $black;
  }

  &__text {
    font-size: 14px;
    line-height: normal;
    color: $black;
    margin: 30px 0;
    text-align: left;

    &--bottom {
      position: absolute;
      bottom: 50px;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__form_container {
    position: relative;
    background-color: #fff;
    padding: 18px 19px 24px;
    width: 400px;
    min-height: 552px;
    height: fit-content;
    text-align: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &--lg {
      min-height: 620px;
    }
  }

  &__form_img {
    background: url("/images/auth_background.png") center no-repeat;
    background-size: cover;
    width: 400px;
    align-self: stretch;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__button {
    width: 100%;
    margin: 15px 0;
    height: 28px;
  }

  &__link {
    font-size: 12px;
    line-height: 2;
    color: $blue_10;

    &-button {
      display: inline-block;
      font-size: 14px;
      line-height: 28px;
      width: 100%;
      margin: 15px 0;
      border-radius: 4px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &__form_wrapper {
    padding: 0 45px;

    .ant-input {
      height: 28px;
    }

    .show-help-leave {
      display: none;
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-form-item-label {
      display: block;
      text-align: left;
      line-height: 1;

      label {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        color: $grey_8c;
      }
    }

    .ant-input:hover {
      border-color: $blue_10;
    }

    .ant-form-item-control:not(.has-error) {
      .ant-input:focus {
        border-color: $blue_10;
        box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
      }
    }

    .has-error .ant-form-explain {
      position: absolute;
      font-size: 12px;
      line-height: 1;
    }

    .ant-alert.ant-alert-no-icon {
      position: absolute;
      bottom: -100%;
      left: 50%;
      display: block;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
}

// @import '../../styles/common/auth';
// @import '../../styles/LNQglobalVars';

// .serverError {
//   color: #f5222d;
// }

// .auth {
//   &__subtitle {
//     margin-bottom: 18px;
//   }

//   &__button {
//     margin-bottom: 20px;
//   }

//   &__forgot_password {
//     margin: -24px 0 18px;
//     text-align: right;
//   }
// }
