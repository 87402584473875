$primary-color: #fdbc15;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;
$success-color: #52c41a;
$success-color-bg: #d2fdbd;
$red_e3: #e33d34;
$red_f0: #fff1f0;
$red_9e: #ffa39e;
$red_95: #ff9695;

$grey_f2: #f2f2f4;
$grey_f6: #f6f6f6;
$grey_f7: #f7f7f7;
$grey_f9: #f9f9f9;
$grey_e6: #e6e6ef;
$grey_e8: #e8e8e8;
$grey_e9: #e9e9e9;
$grey_cc: #ccc;
$grey_b2: #b2b2b2;
$grey_bc: #bcbcbc;
$grey_8c: #8c8c8c;
$grey_333: #333;
$grey_39: #39394e;
$grey_9c: #9c9ca6;

$blue_10: #108ee9;
$blue_83: #83b2ee;
$orange_fe: #febd17;
$green_62: #62bf4c;
$purple: #383750;

$borderRadius_3: 3px;
$borderRadius_8: 8px;

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
  flex110 = flex: 1 1 0;
  Two fixes here:
  1. `flex: 1 1 0` gets translated to `flex: 1 1 auto` by our css build system.
  2. Firefox doesn't respect `flex-basis: 0` without 'min-width: 0': https://github.com/philipwalton/flexbugs/issues/41
*/
@mixin flex110($grow: 1) {
  flex-grow: $grow;
  flex-shrink: 1;
  flex-basis: 0px; // prevent unitless value
  min-width: 0; // fix for firefox: https://github.com/philipwalton/flexbugs/issues/41
}
